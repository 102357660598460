import dayjs, { Dayjs } from 'dayjs';
import { AMT_MIN_TRIP_DURATION } from '../../../shared/consts/app.const';

export const getCatalogName = (
  catalogId: string,
  departureDate: string | Dayjs,
  returnDate: string | Dayjs,
): string => {
  // @TODO: Currently there is no better way, we're doing the same as P&S
  const departureDateFormatted = dayjs.isDayjs(departureDate)
    ? departureDate
    : dayjs(departureDate);
  const returnDateFormatted = dayjs.isDayjs(departureDate)
    ? returnDate
    : dayjs(returnDate);
  const differenceInDays = (returnDateFormatted as Dayjs).diff(
    departureDateFormatted,
    'day',
  );
  if (differenceInDays > AMT_MIN_TRIP_DURATION) {
    return `${catalogId}-AMT`;
  }
  return catalogId;
};

export default getCatalogName;
