import {
  type BookingConfig,
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { CANCELLATION_LIMIT_UNLIMITED_VALUE } from '../consts/app.const';
import { AddonsEnum } from '../consts/optionsAndAddons.const';
import { BookingStepsContextState } from '../../context/bookingContext/bookingContext.types';

const isTripCostApplicable = (
  bookingSteps: BookingStepsContextState,
  bookingConfig: BookingConfig,
): boolean =>
  <boolean>(
    (bookingConfig[BookingConfigEnum.TripCostVisible] &&
      (+bookingSteps[BookingStepValueEnum.QuotationProposals][
        BookingSubStepValueEnum.CancellationLimit
      ] === CANCELLATION_LIMIT_UNLIMITED_VALUE ||
        bookingSteps[BookingStepValueEnum.QuotationProposals][
          BookingSubStepValueEnum.Addons
        ][AddonsEnum.CFAR]))
  );

export default isTripCostApplicable;
