import { Dayjs } from 'dayjs';
import { SxProps } from '@mui/material';
import { BookingSubStepOptionType } from '../../context/bookingContext/bookingContext.types';

export function isTravellerAgeAndBirthdate(
  obj: TravellerAgeAndBirthdate | unknown,
): obj is TravellerAgeAndBirthdate {
  return (obj as TravellerAgeAndBirthdate).age !== undefined;
}

export interface TravellerAgeAndBirthdate {
  age: string;
  dateOfBirth?: Dayjs | null;
}

export interface Form {
  departureDate: Dayjs;
  destination: BookingSubStepOptionType[];
  promoCode: string;
  returnDate: Dayjs;
  travellersType: string;
  tripDetails: string;
  travellersAge: TravellerAgeAndBirthdate[];
  maxTripDuration?: string;
}

export interface PromotionPropsBase {
  control?: any;
  t: (key: string) => string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
}
