import React, { createContext, useState, useMemo } from 'react';
import { FormQuotationInformationForm } from '../../components/QuotationInformationForm/quoation-information.interface';
import { MedicalPreScreeningForm } from '../../components/QuotationInformationForm/MedicalScreeningInformation/MedicalPreScreening';

interface CacheStoreFields {
  personalInformationState: FormQuotationInformationForm | null;
  medicalPreScreeningFormState: MedicalPreScreeningForm | null;
  isSaveQuoteAndBookingDataLoading: boolean | null;
  currentStep: number;
  mobileSummaryVisible: boolean;
  mobileSummaryPosition: number | null;
}

export type CacheStoreCallbackResult<TK extends keyof CacheStoreFields> =
  | CacheStoreFields[TK]
  | undefined
  | null;

export type UpdateCallback<TK extends keyof CacheStoreFields> = (
  value: CacheStoreCallbackResult<TK>,
) => CacheStoreCallbackResult<TK>;
interface CacheStore {
  store: Partial<CacheStoreFields>;
  setCacheValue: <TK extends keyof CacheStoreFields>(
    key: TK,
    value: CacheStoreFields[TK],
  ) => void;
  update: <TK extends keyof CacheStoreFields>(
    key: TK,
    callback: UpdateCallback<TK>,
  ) => void;
}

const CacheDataContext = createContext<CacheStore>({
  store: {},
  setCacheValue: () => ({}),
  update: () => ({}),
});

function CacheDataProvider({
  children,
}: React.PropsWithChildren): React.JSX.Element {
  const [store, setStore] = useState<Partial<CacheStoreFields>>({});

  const setCacheValue = <TK extends keyof CacheStoreFields>(
    key: TK,
    value: CacheStoreCallbackResult<TK>,
  ): void => {
    setStore((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const update = <TK extends keyof CacheStoreFields>(
    key: TK,
    callback: UpdateCallback<TK>,
  ): void => {
    const storeValue = store[key];
    const result = callback(storeValue);
    setCacheValue(key, result);
  };

  const contextValue = useMemo(
    () => ({
      store,
      setCacheValue,
      update,
    }),
    [store],
  );

  return (
    <CacheDataContext.Provider value={contextValue}>
      {children}
    </CacheDataContext.Provider>
  );
}

export { CacheDataProvider, CacheDataContext };
