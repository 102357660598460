import { Dayjs } from 'dayjs';

enum BookingDataEnum {
  Id = 'id',
  CurrentStep = 'currentStep',
  Catalog = 'catalog',
  Partner = 'partner',
  AggregatorId = 'aggregatorId',
  AffiliateId = 'affiliateId',
  Region = 'region',
  Destination = 'destination',
  CountryCode = 'countryCode',
  Product = 'product',
  ProductType = 'productType',
  CancellationLimit = 'cancellationLimit',
  AmtTripLength = 'amtTripLength',
  Proxy = 'proxy',
  ExpireDate = 'expireDate',
  CreatedDate = 'createdDate',
  StartDate = 'startDate',
  EndDate = 'endDate',
  PromoCode = 'promoCode',
  IsCruise = 'isCruise',
  IsWinterSport = 'isWinterSport',
  IsValuables = 'isValuables',
  ProductName = 'productName',
  Addons = 'addons',
  Excess = 'excess',
  DiscountAmount = 'discountAmount',
  DiscountPercentage = 'discountPercentage',
  DiscountApprovedBy = 'discountApprovedBy',
  DiscountReason = 'discountReason',
  DiscountRequestedAt = 'discountRequestedAt',
  IsDiscountApproved = 'isDiscountApproved',
  MaxTripDuration = 'maxTripDuration',
  ExternalBookingId = 'externalBookingId',
  ExternalBacklink = 'externalBacklink',
  BusinessUnitId = 'businessUnitId',
  PEMCScreeningId = 'pemcScreeningId',
  Channel = 'channel',
  ChannelId = 'channelId',
  CreatedAgentUserId = 'createdAgentUserId',
  CustomerId = 'customerId',
  PersistentQuoteId = 'persistentQuoteId',
  Status = 'status',
  ExternalId = 'externalId',
  PrevBookingId = 'prevBookingId',
  TotalPaid = 'totalPaid',
}

enum ProxyEnum {
  Id = 'id',
  QuoteId = 'quoteId',
  PolicyId = 'policyId',
  Amount = 'amount',
  BusinessUnitCode = 'businessUnitCode',
  BrandCode = 'brandCode',
  Active = 'active',
}

enum ConsentEnum {
  Id = 'id',
  CreatedDate = 'createdDate',
  ExpireDate = 'expireDate',
  Usages = 'usages',
}

enum ConsentUsageEnum {
  Id = 'id',
  Type = 'type',
  Version = 'version',
  IsAgentExclusive = 'isAgentExclusive',
}

enum AmountEnum {
  Amount = 'amount',
  Currency = 'currency',
}

enum CustomerEnum {
  EmailAddress = 'emailAddress',
  Title = 'title',
  FirstName = 'firstName',
  LastName = 'lastName',
  DateOfBirth = 'dateOfBirth',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  AddressLine3 = 'addressLine3',
  AddressLine4 = 'addressLine4',
  PostalCode = 'postalCode',
  CountryCode = 'countryCode',
  PhoneNumber1 = 'phoneNumber1',
  PhoneNumber2 = 'phoneNumber2',
  Username = 'username',
  AccessToken = 'accessToken',
}

enum CompanionEnum {
  Id = 'id',
  IsCustomer = 'isCustomer',
  FirstName = 'firstName',
  LastName = 'lastName',
  DateOfBirth = 'dateOfBirth',
  Age = 'age',
  Token = 'token',
}

enum BookingManagementEnum {
  BookingData = 'bookingData',
  Customer = 'customer',
  Companions = 'companions',
  Consents = 'consents',
}

enum BookingDataStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  RECREATED = 'RECREATED',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
  ABANDONED = 'ABANDONED',
}

export enum BookingDataChanel {
  RETAIL_HELIO = 'RETAIL_HELIO',
  RETAIL_PORTAL = 'RETAIL_PORTAL',
  WHITELABEL = 'WHITELABEL',
}

interface BookingData {
  [BookingDataEnum.CurrentStep]: string;
  [BookingDataEnum.Catalog]: string;
  [BookingDataEnum.Region]: string;
  [BookingDataEnum.Destination]: string;
  [BookingDataEnum.CountryCode]: string;
  [BookingDataEnum.Product]: string;
  [BookingDataEnum.CreatedDate]: string;
  [BookingDataEnum.StartDate]: string;
  [BookingDataEnum.EndDate]: string;
  [BookingDataEnum.ProductName]: string;
  [BookingDataEnum.Addons]: { [key: string]: boolean | undefined };
  [BookingDataEnum.Excess]: string;
  [BookingDataEnum.ProductType]?: string;
  [BookingDataEnum.CancellationLimit]?: string;
  [BookingDataEnum.AmtTripLength]?: string;
  [BookingDataEnum.IsValuables]?: boolean;
  [BookingDataEnum.PromoCode]?: string;
  [BookingDataEnum.IsWinterSport]?: boolean;
  [BookingDataEnum.IsCruise]?: boolean;
  [BookingDataEnum.Proxy]?: Proxy;
  [BookingDataEnum.ExpireDate]?: string;
  [BookingDataEnum.DiscountAmount]?: string;
  [BookingDataEnum.DiscountPercentage]?: string;
  [BookingDataEnum.DiscountApprovedBy]?: string;
  [BookingDataEnum.DiscountReason]?: string;
  [BookingDataEnum.DiscountRequestedAt]?: string;
  [BookingDataEnum.IsDiscountApproved]?: boolean;
  [BookingDataEnum.Id]?: string;
  [BookingDataEnum.PEMCScreeningId]?: string;

  [BookingDataEnum.BusinessUnitId]?: string;
  [BookingDataEnum.Channel]?: BookingDataChanel;
  [BookingDataEnum.ChannelId]?: number;
  [BookingDataEnum.CreatedAgentUserId]?: number;
  [BookingDataEnum.CustomerId]?: string;
  [BookingDataEnum.PersistentQuoteId]?: string;
  [BookingDataEnum.Status]?: BookingDataStatus;
  [BookingDataEnum.ExternalId]?: string;
  [BookingDataEnum.PrevBookingId]?: string;
  [BookingDataEnum.TotalPaid]?: number;
}

interface BookingDataResponse extends BookingManagement {
  [BookingDataEnum.Id]: string;
}

interface Proxy {
  [ProxyEnum.Id]?: string;
  [ProxyEnum.QuoteId]: string;
  [ProxyEnum.PolicyId]: string;
  [ProxyEnum.Amount]: Amount;
  [ProxyEnum.BrandCode]: string;
  [ProxyEnum.BusinessUnitCode]: string;
  [ProxyEnum.Active]?: boolean;
}

interface Consent {
  [ConsentEnum.Id]?: number;
  [ConsentEnum.CreatedDate]?: string;
  [ConsentEnum.ExpireDate]?: string;
  [ConsentEnum.Usages]: ConsentUsage[];
}

interface ConsentUsage {
  [ConsentUsageEnum.Id]?: number;
  [ConsentUsageEnum.Type]: string;
  [ConsentUsageEnum.Version]: number;
  [ConsentUsageEnum.IsAgentExclusive]: boolean;
}

interface Amount {
  [AmountEnum.Amount]: number;
  [AmountEnum.Currency]: string;
}

interface Customer {
  [CustomerEnum.EmailAddress]?: string;
  [CustomerEnum.Title]?: string;
  [CustomerEnum.FirstName]?: string;
  [CustomerEnum.LastName]?: string;
  [CustomerEnum.DateOfBirth]: string;
  [CustomerEnum.AddressLine1]?: string;
  [CustomerEnum.AddressLine2]?: string;
  [CustomerEnum.AddressLine3]?: string;
  [CustomerEnum.AddressLine4]?: string;
  [CustomerEnum.PostalCode]?: string;
  [CustomerEnum.CountryCode]?: string;
  [CustomerEnum.PhoneNumber1]?: string;
  [CustomerEnum.PhoneNumber2]?: string;
  [CustomerEnum.Username]?: string;
  [CustomerEnum.AccessToken]?: string;
}

interface Companion {
  [CompanionEnum.Id]?: number;
  [CompanionEnum.DateOfBirth]: string;
  [CompanionEnum.Age]?: number;
  [CompanionEnum.IsCustomer]?: boolean;
  [CompanionEnum.FirstName]?: string;
  [CompanionEnum.LastName]?: string;
  [CompanionEnum.Token]?: string;
}

interface BookingManagement {
  [BookingManagementEnum.BookingData]: BookingData;
  [BookingManagementEnum.Customer]?: Customer;
  [BookingManagementEnum.Companions]: Companion[];
  [BookingManagementEnum.Consents]?: Consent[];
}

export {
  BookingManagement,
  BookingManagementEnum,
  BookingDataStatus,
  BookingData,
  BookingDataEnum,
  Customer,
  CustomerEnum,
  Companion,
  CompanionEnum,
  Amount,
  AmountEnum,
  ProxyEnum,
  Consent,
  ConsentEnum,
  ConsentUsage,
  ConsentUsageEnum,
  BookingDataResponse,
};
