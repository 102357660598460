import dayjs from 'dayjs';
import { format } from 'date-fns';
import { ApiDateFormat, ApiOutputDateFormat } from '../consts/app.const';
import {
  BookingConfig,
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import {
  getPartnerCode,
  prepareAddons,
  prepareDestinationCountryList,
} from './insuranceOptions';
import {
  BookingStepsContextState,
  BookingSubStepsQuotationForm,
  BookingSubStepsQuotationProposals,
} from '../../context/bookingContext/bookingContext.types';
import {
  InsuranceData,
  InsuranceDataEnum,
  InsuranceEnum,
  InsurancePayload,
} from '../interfaces/insurance.interface';
import {
  BookingQuoteResponse,
  BookingQuoteResponseEnum,
} from '../../components/BookingWidget/BookingQuoteManagment/bookingQuoteResponse.interface';
import {
  BookingQuoteEnum,
  BookingQuoteInsuranceDataEnum,
  BookingQuoteInsuranceEnum,
} from '../../components/BookingWidget/BookingQuoteManagment/bookingQuote.interface';
import { DecodedSessionToken } from '../../context/sessionContext/sessionContext.interface';
import isTripCostApplicable from './tripCost.helper';

const getInsurancePayload = (
  bookingSteps: BookingStepsContextState,
  bookingConfigData: BookingConfig,
  bookingQuoteResponse: BookingQuoteResponse | null,
  bookingReferenceId: string | null = null,
  decodedSessionToken: DecodedSessionToken | null = null,
): InsurancePayload => {
  const quotationProposals: BookingSubStepsQuotationProposals =
    bookingSteps[BookingStepValueEnum.QuotationProposals] || {};
  const quotationForm: BookingSubStepsQuotationForm =
    bookingSteps[BookingStepValueEnum.QuotationForm] || {};

  const {
    [BookingSubStepValueEnum.Addons]: addonsValues,
    [BookingSubStepValueEnum.CancellationLimit]: cancellationLimit,
    [BookingSubStepValueEnum.MaxTripDuration]: maxTripDuration,
    [BookingSubStepValueEnum.Excess]: excessValues,
    [BookingSubStepValueEnum.CreditAmount]: creditAmount,
    [BookingSubStepValueEnum.AgentsDiscount]: agentDiscount,
  } = quotationProposals;

  const salesChannel =
    bookingQuoteResponse?.[BookingQuoteResponseEnum.Quote]?.genericData
      ?.salesChannel || bookingConfigData[BookingConfigEnum.Channel].subType;

  const shopId =
    decodedSessionToken?.businessUnit ||
    bookingQuoteResponse?.[BookingQuoteResponseEnum.Quote]?.genericData
      ?.shopId ||
    bookingConfigData[BookingConfigEnum.Channel].businessUnits[0]?.shopId;

  const agentId =
    decodedSessionToken?.agentId ||
    bookingQuoteResponse?.[BookingQuoteResponseEnum.Quote]?.genericData
      ?.agentId;

  const discretionaryDiscount = agentDiscount?.discountStatus ===
    'APPROVED' && {
    [InsuranceDataEnum.DiscretionaryDiscountReason]:
      agentDiscount?.selectedDiscountReason,
    ...(agentDiscount.isPercentageDiscount
      ? {
          [InsuranceDataEnum.DiscretionaryDiscountPercentage]:
            +agentDiscount.discountAmount / 100,
        }
      : {
          [InsuranceDataEnum.DiscretionaryDiscountAmount]:
            +agentDiscount.discountAmount,
        }),
  };

  const insuranceData: InsuranceData = {
    [InsuranceDataEnum.Brand]: bookingConfigData[BookingConfigEnum.BrandCode],
    [InsuranceDataEnum.DestinationCountry]: prepareDestinationCountryList(
      quotationForm[BookingSubStepValueEnum.GeographicalZone],
    ),
    [InsuranceDataEnum.InsuranceAddons]: prepareAddons(addonsValues),
    [InsuranceDataEnum.State]:
      bookingSteps.quotationInformationForm.informationPersonal?.state,
    [InsuranceDataEnum.PartnerCode]: getPartnerCode(bookingSteps),
    [BookingQuoteInsuranceDataEnum.SalesChannel]: salesChannel,
    [BookingQuoteInsuranceDataEnum.ShopId]: shopId,
    [BookingQuoteInsuranceDataEnum.AgentId]: agentId,
    [InsuranceDataEnum.CreditAmount]: creditAmount
      ? `${creditAmount}`
      : undefined,
    ...discretionaryDiscount,
  };
  if (excessValues?.length) {
    insuranceData[InsuranceDataEnum.Excess] = excessValues;
  }
  if (cancellationLimit) {
    insuranceData[InsuranceDataEnum.CancellationLimit] = cancellationLimit;
  }
  if (maxTripDuration) {
    insuranceData[InsuranceDataEnum.MaxTripDuration] = maxTripDuration;
  }
  if (bookingQuoteResponse?.[BookingQuoteResponseEnum.Quote]?.genericData) {
    if (
      bookingQuoteResponse[BookingQuoteEnum.Insurance][
        BookingQuoteInsuranceEnum.InsuranceData
      ]?.[BookingQuoteInsuranceDataEnum.Duration]
    ) {
      insuranceData[InsuranceDataEnum.Duration] =
        bookingQuoteResponse[BookingQuoteResponseEnum.Insurance][
          BookingQuoteInsuranceEnum.Duration
        ];
    }
  }
  if (
    bookingSteps[BookingStepValueEnum.QuotationForm][
      BookingSubStepValueEnum.TripCost
    ] &&
    isTripCostApplicable(bookingSteps, bookingConfigData)
  ) {
    insuranceData[InsuranceDataEnum.AmountToInsure] =
      bookingSteps[BookingStepValueEnum.QuotationForm][
        BookingSubStepValueEnum.TripCost
      ];
  }

  return {
    [InsuranceEnum.BookingDate]: format(
      bookingQuoteResponse?.insurance?.bookingDate
        ? new Date(bookingQuoteResponse?.insurance?.bookingDate)
        : new Date(),
      ApiDateFormat,
    ),
    [InsuranceEnum.StartDate]: `${dayjs(
      quotationForm[BookingSubStepValueEnum.DepartureDate],
    ).format(ApiOutputDateFormat)}`,
    [InsuranceEnum.EndDate]: `${dayjs(
      quotationForm[BookingSubStepValueEnum.ReturnDate],
    ).format(ApiOutputDateFormat)}`,
    [InsuranceEnum.InsuranceData]: insuranceData,
    [InsuranceDataEnum.BookingReferenceId]: bookingReferenceId ?? undefined,
  };
};

export default getInsurancePayload;
