import { format } from 'date-fns';
import { Dayjs } from 'dayjs';
import {
  BookingStepsContextState,
  BookingSubStepsQuotationProposals,
} from '../../../context/bookingContext/bookingContext.types';
import {
  BookingChannel,
  BookingConfig,
  BookingConfigEnum,
  BookingStep,
  BookingStepEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../bookingSteps.interface';
import {
  BookingQuote,
  BookingQuoteBeneficiaries,
  BookingQuoteEnum,
  BookingQuoteInsuranceDataEnum,
  BookingQuoteInsuranceEnum,
  BookingQuoteProductEnum,
  BookingQuoteSubscriptionEnum,
} from './bookingQuote.interface';
import {
  ApiDateFormat,
  ApiOutputDateFormat,
} from '../../../shared/consts/app.const';
import {
  getPartnerCode,
  prepareAddons,
  prepareDestinationCountryList,
} from '../../../shared/helpers/insuranceOptions';
import { ProductEnum } from '../../QuotationProposals/useFetchProposalsConf';
import { getSubscriberObject } from '../../../shared/helpers/subscriber.helper';
import getBeneficiariesArray from '../../../shared/helpers/beneficiaries.helper';
import { BookingDataResponse } from '../BookingManagment/bookingManagment.interface';
import {
  BookingQuoteResponse,
  BookingQuoteResponseEnum,
} from './bookingQuoteResponse.interface';
import { DecodedSessionToken } from '../../../context/sessionContext/sessionContext.interface';
import formatApiDate from '../helpers/formatDate';

const getPrice = (
  bookingProposal: BookingSubStepsQuotationProposals,
): string => {
  if (bookingProposal[BookingSubStepValueEnum.TotalPrice]) {
    return bookingProposal[BookingSubStepValueEnum.TotalPrice].toString();
  }
  return bookingProposal[BookingSubStepValueEnum.Proposal][
    ProductEnum.Price
  ].grossPremium.toString();
};

const parseDataToQuote = (
  bookingStepsContextState: BookingStepsContextState,
  bookingConfig: BookingConfig,
  {
    email,
    quoteId,
    agentEmail,
  }: { email?: string | boolean; quoteId?: string; agentEmail?: string } = {},
  decodedSessionToken: DecodedSessionToken | null = null,
  bookingDataResponse: BookingDataResponse | null = null,
  bookingQuoteResponse: BookingQuoteResponse | null = null,
  bookingReferenceId: string | null = null,
): BookingQuote => {
  const activeStep = bookingConfig[BookingConfigEnum.BookingSteps].filter(
    (step: BookingStep) =>
      step[BookingStepEnum.KeyName] ===
      bookingStepsContextState[BookingStepValueEnum.CurrentStep],
  )[0];

  const beneficiaries: BookingQuoteBeneficiaries[] = getBeneficiariesArray(
    bookingStepsContextState,
    bookingConfig,
    bookingDataResponse,
  );

  const maxTripDuration =
    bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.MaxTripDuration
    ];

  const agentsDiscount =
    bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.AgentsDiscount
    ];

  const discretionaryDiscount = agentsDiscount?.discountStatus ===
    'APPROVED' && {
    [BookingQuoteInsuranceDataEnum.DiscretionaryDiscountReason]:
      agentsDiscount?.selectedDiscountReason,
    ...(agentsDiscount.isPercentageDiscount
      ? {
          [BookingQuoteInsuranceDataEnum.DiscretionaryDiscountPercentage]:
            +agentsDiscount.discountAmount / 100,
        }
      : {
          [BookingQuoteInsuranceDataEnum.DiscretionaryDiscountAmount]:
            +agentsDiscount.discountAmount,
        }),
  };

  const salesChannel =
    bookingQuoteResponse?.[BookingQuoteResponseEnum.Quote]?.genericData
      ?.salesChannel || bookingConfig[BookingConfigEnum.Channel].subType;

  const shopId =
    decodedSessionToken?.businessUnit ||
    bookingQuoteResponse?.[BookingQuoteResponseEnum.Quote]?.genericData
      ?.shopId ||
    bookingConfig[BookingConfigEnum.Channel].businessUnits[0]?.shopId;

  const agentId =
    decodedSessionToken?.agentId ||
    bookingQuoteResponse?.[BookingQuoteResponseEnum.Quote]?.genericData
      ?.agentId;

  const quote: BookingQuote = {
    ...(!email
      ? {
          [BookingQuoteEnum.QuotationStep]:
            activeStep[BookingStepEnum.StepNameApi],
          [BookingQuoteEnum.Product]: {
            [BookingQuoteProductEnum.ProductGroup]: [
              {
                [BookingQuoteProductEnum.Code]:
                  bookingStepsContextState[
                    BookingStepValueEnum.QuotationProposals
                  ][BookingSubStepValueEnum.Proposal][ProductEnum.Code],
              },
            ],
          },
        }
      : {}),
    [BookingQuoteEnum.Insurance]: {
      [BookingQuoteInsuranceEnum.BookingDate]: format(
        bookingQuoteResponse?.insurance?.bookingDate
          ? new Date(bookingQuoteResponse?.insurance?.bookingDate)
          : new Date(),
        ApiDateFormat,
      ),
      [BookingQuoteInsuranceEnum.StartDate]: formatApiDate(
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.DepartureDate
        ],
      ),
      [BookingQuoteInsuranceEnum.EndDate]: formatApiDate(
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.ReturnDate
        ],
      ),
      [BookingQuoteInsuranceEnum.InsuranceData]: {
        [BookingQuoteInsuranceDataEnum.Brand]:
          bookingConfig[BookingConfigEnum.BrandCode],
        [BookingQuoteInsuranceDataEnum.DestinationCountry]:
          bookingStepsContextState[BookingStepValueEnum.QuotationForm][
            BookingSubStepValueEnum.GeographicalZone
          ]?.length > 0
            ? prepareDestinationCountryList(
                bookingStepsContextState[BookingStepValueEnum.QuotationForm][
                  BookingSubStepValueEnum.GeographicalZone
                ],
              )
            : (bookingDataResponse?.bookingData.destination as string),
        ...(maxTripDuration
          ? {
              [BookingQuoteInsuranceDataEnum.MaxTripDuration]: +maxTripDuration,
            }
          : {}),
        [BookingQuoteInsuranceDataEnum.CancellationLimit]:
          bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
            BookingSubStepValueEnum.CancellationLimit
          ],
        [BookingQuoteInsuranceDataEnum.Excess]:
          bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
            BookingSubStepValueEnum.Excess
          ],
        [BookingQuoteInsuranceDataEnum.ProductType]:
          bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
            BookingSubStepValueEnum.ProductType
          ],
        [BookingQuoteInsuranceDataEnum.SalesChannel]: salesChannel,
        [BookingQuoteInsuranceDataEnum.InsuranceAddons]: prepareAddons(
          bookingStepsContextState[BookingStepValueEnum.QuotationProposals][
            BookingSubStepValueEnum.Addons
          ],
        ),
        ...(email
          ? {
              [BookingQuoteInsuranceDataEnum.QuoteId]: quoteId,
            }
          : {}),
        [BookingQuoteInsuranceDataEnum.PartnerCode]: getPartnerCode(
          bookingStepsContextState,
        ),
        [BookingQuoteInsuranceDataEnum.ShopId]: shopId,
        [BookingQuoteInsuranceDataEnum.AgentId]: agentId,
        ...discretionaryDiscount,
      },
    },
    ...(!email
      ? {
          [BookingQuoteEnum.Subscription]: {
            [BookingQuoteSubscriptionEnum.Price]: getPrice(
              bookingStepsContextState[BookingStepValueEnum.QuotationProposals],
            ),
          },
        }
      : {}),
    [BookingQuoteEnum.Subscriber]: getSubscriberObject(
      bookingConfig,
      bookingStepsContextState,
    ),
    [BookingQuoteEnum.Beneficiaries]: beneficiaries,
    ...(email
      ? {
          [BookingQuoteEnum.Email]: email,
        }
      : {}),
    [BookingQuoteEnum.Promotion]: {
      [BookingQuoteInsuranceEnum.PromotionCode]:
        bookingStepsContextState[BookingStepValueEnum.QuotationForm][
          BookingSubStepValueEnum.PromoCode
        ],
    },
  };

  // @TODO it can be that this number will be also in different place
  if (
    bookingQuoteResponse?.[BookingQuoteResponseEnum.Insurance]?.[
      BookingQuoteInsuranceEnum.Pnr
    ]
  ) {
    quote[BookingQuoteEnum.Insurance][BookingQuoteInsuranceEnum.Pnr] =
      bookingQuoteResponse[BookingQuoteResponseEnum.Insurance][
        BookingQuoteInsuranceEnum.Pnr
      ];
  }

  if (bookingReferenceId) {
    quote[BookingQuoteEnum.Insurance][BookingQuoteInsuranceEnum.Pnr] =
      bookingReferenceId;
  }

  // @TODO fields below are from quote response, but in the future they can be also from eShop login data
  if (bookingQuoteResponse?.[BookingQuoteResponseEnum.Quote]?.genericData) {
    if (
      quote[BookingQuoteEnum.Insurance][
        BookingQuoteInsuranceEnum.InsuranceData
      ]?.[BookingQuoteInsuranceDataEnum.Duration]
    ) {
      quote[BookingQuoteEnum.Insurance][
        BookingQuoteInsuranceEnum.InsuranceData
      ][BookingQuoteInsuranceDataEnum.Duration] =
        bookingQuoteResponse[BookingQuoteResponseEnum.Insurance][
          BookingQuoteInsuranceEnum.Duration
        ];
    }
  }

  return quote;
};

export default parseDataToQuote;
